export enum StorageKeys {
  Auth = 'auth',
  OAuth = 'oAuth',
  isOAuth = 'isOAuth',
  Language = 'language',
  Role = 'selected-role',
  PinnedUsers = 'pinned-users',
  GoogleLoginDisabled = 'googleLoginDisabled',
  State = 'state',
  ChatBot = 'chatbot'
}