import { createAction, props } from '@ngrx/store';

export const navigateToSignInPage = createAction('[Auth Manager] Open Sign In Page');
export const navigateToRestorePasswordPage = createAction('[Auth Manager] Open Restore Password Page');
export const navigateToRestorePasswordSuccessPage = createAction('[Auth Manager] Open Restore Password Success Page');
export const navigateToPhoneSetUpPage = createAction('[Auth Manager] Open Phone Set Up Page');
export const navigateToVerifyPhonePage = createAction('[Auth Manager] Open Phone Verification Page');
export const navigateToLoginPage = createAction('[Auth Manager] Open Login Page');
export const navigateToSavePhonePage = createAction('[Auth Manager] Save Phone Verification Page');
export const navigateToHealthTalkAction = createAction('[Auth Manager] Navigate to HealthTalk', props<{appointmentId: string}>());
export const navigateToHealthTalkFailureAction = createAction('[Auth Manager] Navigate to HealthTalk Failure', props<{ error: any }>());
export const signUpFinishedRedirectAction = createAction('[Auth Manager] Sign Up Finished Redirect');
export const signInFinishedRedirectAction = createAction(
  '[Auth Manager] Sign In Finished Redirect',
  props<{ path?: string[], queryParams?: any }>()
);
export const backToLogin = createAction('[Auth Manager] Back To Login');
