import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { catchError, filter, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TokenService } from '../services/token.service';
import { getCodeVerifier, selectAccessToken, selectUserId } from '../state-management/auth-manager.selectors';
import { NGXLogger } from 'ngx-logger';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { Actions, ofType } from '@ngrx/effects';
import { AuthRouteParams } from '../models/enums';
import {
  createCodeVerifier,
  logout,
  logoutFinished,
  renewTokenFailureAction,
  requestTokensAction,
  requestTokensFailureAction,
  requestWhoAmISuccessAction,
  signInWithTempTokenAction,
  signInWithTempTokenFinishedAction,
} from '../state-management/auth-manager.actions';
import { generalRouteNames } from '@medrecord/routes-general';

@Injectable()
export class AuthorizedGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    private logger: NGXLogger,
    private tokenService: TokenService,
    private actions$: Actions,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames
  ) {}

  canActivateChild(
    routeSnapshot: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkCanActivateAccess(routeSnapshot);
  }

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkCanActivateAccess(routeSnapshot);
  }

  private checkIfValidToken(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectAccessToken),
      first(),
      switchMap((accessToken) => {
        if (!accessToken) return this.doSignInWithTempToken(routeSnapshot);

        return this.tokenService.validateToken().pipe(
          switchMap(() => {
            this.logger.info('[Access Token] ', accessToken);
            return of(true);
          }),
          catchError(() => this.redirectToAuth())
        );
      })
    );
  }

  private redirectToAuth(): Observable<boolean> {
    this.router.navigate([this.authRouteNames.Entry]);
    return of(false);
  }

  private doLogout(): Observable<boolean> {
    this.store.dispatch(logout());

    return this.actions$.pipe(
      ofType(logoutFinished),
      switchMap(() => this.redirectToAuth())
    );
  }

  private doSignInWithTempToken(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const tempToken = routeSnapshot.queryParams[AuthRouteParams.TemporaryToken];
    const referralUrl = routeSnapshot.queryParams.referral_url;
    const lang = routeSnapshot.queryParams[AuthRouteParams.Language];
    if (!tempToken) return this.redirectToAuth();

    this.store.dispatch(createCodeVerifier());

    return this.store.select(getCodeVerifier).pipe(
      withLatestFrom(this.store.select(selectUserId)),
      filter(([codeVerifier]) => !!codeVerifier),
      switchMap(([_, oldUserId]) => {
        this.store.dispatch(signInWithTempTokenAction({ tempToken }));

        return this.actions$.pipe(
          ofType(signInWithTempTokenFinishedAction),
          switchMap((payload) => {
            if (!payload.success && oldUserId) return this.doLogout();
            else if (!payload.success) return this.redirectToAuth();

            const code = new URL(payload.redirectUri).searchParams.get(AuthRouteParams.Code);
            if (!code && oldUserId) return this.doLogout();
            else if (!code) return this.redirectToAuth();

            this.store.dispatch(
              requestTokensAction({
                code,
                redirectPath: referralUrl ? [generalRouteNames.Admin.Entry] : [generalRouteNames.PaymentPlans.Entry],
                redirectQueryParams: { lang },
              })
            );

            // Save referralUrl value to local storage
            localStorage.setItem('referralUrl', referralUrl);

            return this.actions$.pipe(
              ofType(requestWhoAmISuccessAction, requestTokensFailureAction, renewTokenFailureAction),
              switchMap(({ type }) => {
                if (type === requestWhoAmISuccessAction.name) return of(true);
                return this.checkIfValidToken(routeSnapshot);
              })
            );
          })
        );
      })
    );
  }

  private checkCanActivateAccess(
    routeSnapshot: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tempToken = routeSnapshot.queryParams[AuthRouteParams.TemporaryToken];

    if (tempToken) return this.doSignInWithTempToken(routeSnapshot);
    return this.checkIfValidToken(routeSnapshot);
  }
}
