import { ModuleWithProviders, NgModule } from '@angular/core';
import { LogoutService } from './services/logout.service';
import { AuthorizedGuard } from './guards/authorized.guard';
import { AuthManagerConstantsProviders } from './constants';

/** Services */
import { SignInService } from './services/signin.service';
import { SignUpService } from './services/signup.service';
import { PasswordService } from './services/password.service';
import { TokenService } from './services/token.service';
import { AuthRedirectService } from './services/auth-redirect.service';
import { PhoneVerificationService } from './services/phone-verification.service';
import { MedrecordOAuthService } from './services/medrecord-oauth.service';
import { StoreModule } from '@ngrx/store';
import {
  AuthManagerState, authMangerSyncConfig,
  createAuthMangerReducer,
  featureAuthMangerKey
} from './state-management/auth-manager.reducers';
import { FEATURE_STORE_CONFIG, NgrxSyncStorageModule } from '@medrecord/tools-ngrx-sync-storage';
import { EffectsModule } from '@ngrx/effects';
import { LogoutEffects } from './state-management/effects/logout.effects';
import { PasswordEffects } from './state-management/effects/password.effects';
import { RedirectEffects } from './state-management/effects/redirect.effects';
import { SignInEffects } from './state-management/effects/signin.effects';
import { SignUpEffects } from './state-management/effects/signup.effects';
import { TokenEffects } from './state-management/effects/token.effects';
import { PhoneVerificationEffects } from './state-management/effects/phone-verification.effects';
import { provideRoutes, RouterModule, Routes } from '@angular/router';
import { InvitationService } from './services/invitation.service';

@NgModule({
  imports: [
    RouterModule,
    StoreModule.forFeature<AuthManagerState, any>(featureAuthMangerKey, createAuthMangerReducer, FEATURE_STORE_CONFIG),
  
    NgrxSyncStorageModule.forFeature(authMangerSyncConfig),
  
    EffectsModule.forFeature([
      LogoutEffects,
      PasswordEffects,
      RedirectEffects,
      SignInEffects,
      SignUpEffects,
      TokenEffects,
      PhoneVerificationEffects
    ]),
  ],
  providers: [
    AuthorizedGuard,
    LogoutService,
    SignInService,
    SignUpService,
    PasswordService,
    InvitationService,
    TokenService,
    AuthRedirectService,
    PhoneVerificationService,
    MedrecordOAuthService,
  ]
})
export class AuthManagerLibModule {
  /** Will define default constants */
  static configure(
    {
      setDefaultFormRules,
      setDefaultAccessTokenRules,
      routes
    }: {
      setDefaultFormRules?: boolean;
      setDefaultAccessTokenRules?: boolean;
      routes?: Routes;
    }
  ): ModuleWithProviders<AuthManagerLibModule> {
    const providers = [];
    
    if (setDefaultFormRules) {
      providers.push(AuthManagerConstantsProviders.provideFormRules());
    }
    
    if (setDefaultAccessTokenRules) {
      providers.push(AuthManagerConstantsProviders.provideAccessTokenRules());
    }

    if (routes) {
      providers.push(provideRoutes(routes));
    }
    
    return {
      ngModule: AuthManagerLibModule,
      providers
    };
  }
}
