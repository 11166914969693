import { provideEnvironment } from '../providers/provide-environment';

export const provideMockEnvironment = () =>
  provideEnvironment({
    production: false,
    env: 'dev',
    auth: 'auth',
    backend: 'backend',
    middlewareBackend: 'middlewareBackend',
    cmsBackend: 'cmsBackend',
    cmsApiKey: 'cmsApiKey',
    client: 'client',
    clientId: '2d6c9591-ec11-4ed6-9ba2-18c1b3087736',
    showWebsite: false,
    showPayments: true,
    shortClientName: 'MedSafe',
    showAuthReferenceText: true,
  });
