import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { LogoutService } from '../../services/logout.service';
import { logout, logoutEverywhere, logoutFinished } from '../auth-manager.actions';
import { selectSession } from '../auth-manager.selectors';
import { Store } from '@ngrx/store';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';

@Injectable()
export class LogoutEffects {
  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(logout),
    mergeMap(() => {
      return this.logoutService.signOut().pipe(
        switchMap(() => {
          this.storage.removeItem(StorageKeys.PinnedUsers);
          this.storage.removeItem(StorageKeys.State);
          this.storage.removeItem(StorageKeys.Role);
          this.storage.removeItem(StorageKeys.ChatBot);

          this.store.dispatch(logoutFinished());

          return [];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('logout_error', error))])
      );
    })
  );

  @Effect({ dispatch: false })
  logoutEverywhere$ = this.actions$.pipe(
    ofType(logoutEverywhere),
    withLatestFrom(this.store.select(selectSession)),
    mergeMap(([, session]) => {
      console.log({ session });

      return this.logoutService.signOut().pipe(
        mergeMap(() => {
          this.storage.removeItem(StorageKeys.PinnedUsers);
          this.storage.removeItem(StorageKeys.State);
          this.storage.removeItem(StorageKeys.Role);
          this.storage.removeItem(StorageKeys.ChatBot);

          this.store.dispatch(logoutFinished());

          return [];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('logout_everywhere_error', error))])
      );
    })
  );

  constructor(
    private logoutService: LogoutService,
    private actions$: Actions,
    private store: Store,
    private storage: StorageService<StorageKeys>
  ) {}
}
