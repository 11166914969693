import { createAction, props,  } from '@ngrx/store';
import { OAuthSignInResponse, SignInForm } from '../../models/interfaces';
import { Language } from '@medrecord/core';
import { TempTokenSignInResponse } from '../../models/interfaces/signin/temp-token-signin-response.interface';

export const createCodeVerifier = createAction('[Auth Manager] Create Code Verifier');

export const resetSignInData = createAction('[Auth Manager] Reset Sign In Data');

export const signInAction = createAction(
  '[Auth Manager] Sign In',
  props<SignInForm>()
);

export const signInRememberedUserAction = createAction(
  '[Auth Manager] Sign In Remembered User'
);

export const signInWithCredentialsAction = createAction(
  '[Auth Manager] Sign In With Credentials',
  props<SignInForm>()
);

export const signInWithSessionAction = createAction(
  '[Auth Manager] Sign In With Session',
  props<SignInForm>()
);

export const oAuthSignInAction = createAction(
  '[Auth Manager] OAuth Sign In',
  props<OAuthSignInResponse & SignInForm>()
);

export const signInSuccessAction = createAction(
  '[Auth Manager] Sign In Success',
  props<OAuthSignInResponse & SignInForm>()
);

export const signInFailureAction = createAction(
  '[Auth Manager] Sign In Failure',
  props<{ error: any }>()
);


export const signInViaMedrecord = createAction(
  '[Auth Manager] Sign In Via Medrecord'
);

export const signInViaMedrecordSuccess = createAction(
  '[Auth Manager] Sign In Via Medrecord Success',
  /** TODO Set proper payload for login via Medrecord OAuth 2.0 */
  props<{ idToken: string, languageCode: Language }>()
);

export const signInViaMedrecordFailure = createAction(
  '[Auth Manager] Sign In Via Medrecord Failure',
  props<{ error: any }>()
);

export const signInViaGoogle = createAction(
  '[Auth Manager] Sign In Via Google'
);

export const signInViaGoogleSuccess = createAction(
  '[Auth Manager] Sign In Via Google Success',
  props<{ idToken: string, languageCode: Language }>()
);

export const signInViaGoogleFailure = createAction(
  '[Auth Manager] Sign In Via Google Failure',
  props<{ error: any }>()
);

export const signInViaGoogleCompleteSuccess = createAction(
  '[Auth Manager] Sign In Via Google Complete Success',
  props<OAuthSignInResponse>()
);

export const signInViaGoogleCompleteFailure = createAction(
  '[Auth Manager] Sign In Via Google Complete Failure',
  props<{ error: any }>()
);

export const completeSignIn = createAction(
  '[Auth Manager] Complete Login',
  props<OAuthSignInResponse>()
);

export const signInWithTempTokenAction = createAction(
  '[Auth Manager] Sign In With Temporary Token',
  props<{ tempToken: string }>()
);

export const signInWithTempTokenFinishedAction = createAction(
  '[Auth Manager] Sign In With Temporary Token Finished', 
  props<TempTokenSignInResponse>()
);

export const oAuthSignInWithTempTokenAction = createAction(
  '[Auth Manager] OAuth Sign In With Temporary Token', 
  props<OAuthSignInResponse & TempTokenSignInResponse>()
);

export const signInWithTempTokenSuccessAction = createAction(
  '[Auth Manager] Sign In With Temporary Token Success',
  props<OAuthSignInResponse>()
);