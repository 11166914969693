import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, StorageKeys } from '@medrecord/core';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { StorageService } from '@medrecord/tools-storage';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthRouteParams, VerificationStatus } from '../../models/enums';
import { AuthStorageItem } from '../../models/interfaces';
import { TokenGetAuthResponse } from '../../models/interfaces/token/token-getauth-response.interface';
import { AuthRedirectService } from '../../services/auth-redirect.service';
import { MedrecordOAuthService } from '../../services/medrecord-oauth.service';
import { TokenService } from '../../services/token.service';
import {
    checkPhoneCodeSuccess,
    completeSignIn,
    logoutFinished,
    navigateToHealthTalkAction,
    navigateToHealthTalkFailureAction,
    navigateToLoginPage,
    navigateToPhoneSetUpPage,
    navigateToRestorePasswordPage,
    navigateToRestorePasswordSuccessPage,
    navigateToSavePhonePage,
    navigateToSignInPage,
    navigateToVerifyPhonePage,
    requestTokensAction,
    savePhoneSuccess,
    signInFinishedRedirectAction,
    signInSuccessAction,
    signInViaGoogleCompleteSuccess,
} from '../auth-manager.actions';
import { getCodeVerifier, selectRedirectUri } from '../auth-manager.selectors';

@Injectable()
export class RedirectEffects {
  @Effect()
  openSignInPage$ = this.actions$.pipe(
    ofType(navigateToSignInPage, logoutFinished),
    switchMap(() => {
      const auth = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth);

      if (!auth.rememberMe) {
        this.storage.extendItem(StorageKeys.Auth, { session: undefined });
      }

      this.router.navigate([this.authRouteNames.Entry]);

      return [];
    })
  );

  @Effect()
  openRestorePasswordPage$ = this.actions$.pipe(
    ofType(navigateToRestorePasswordPage),
    withLatestFrom(this.store.select(getCodeVerifier)),
    switchMap(([, codeVerifier]) => {
      return from(this.redirectService.navigateToRestorePassword()).pipe(
        switchMap((url) => this.medrecordOAuthService.loginWithMedrecord(codeVerifier, url))
      );
    })
  );

  @Effect()
  openRedirectPage$ = this.actions$.pipe(
    ofType(completeSignIn, checkPhoneCodeSuccess, savePhoneSuccess),
    withLatestFrom(this.store.select(selectRedirectUri)),
    switchMap(([, redirectUri]) => {
      const url = new URL(redirectUri);

      if (!this.storage.getItem(StorageKeys.isOAuth, false)) {
        return [requestTokensAction({ code: url.searchParams.get(AuthRouteParams.Code) })];
      }

      try {
        opener.location.replace(redirectUri);
        close();
      } catch {
        location.replace(redirectUri);
      }

      return [];
    })
  );

  @Effect()
  verificationRedirectAction$ = this.actions$.pipe(
    ofType(signInSuccessAction, signInViaGoogleCompleteSuccess),
    switchMap(({ session, twoFactorStatus, redirectUri }) => {
      const twoFactorOptions = {
        [VerificationStatus.Disabled]: [completeSignIn({ session, twoFactorStatus, redirectUri })],

        [VerificationStatus.Passed]: [completeSignIn({ session, twoFactorStatus, redirectUri })],

        [VerificationStatus.PhoneSetupRequired]: [navigateToPhoneSetUpPage()],

        [VerificationStatus.PhoneVerificationRequired]: [navigateToVerifyPhonePage()],
      };

      return twoFactorOptions[twoFactorStatus];
    })
  );

  @Effect()
  navigateToRestorePasswordSuccessPage$ = this.actions$.pipe(
    ofType(navigateToRestorePasswordSuccessPage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Password.Forgot,
          this.authRouteNames.Password.Restore,
          this.authRouteNames.Password.Success,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToPhoneSetUpPage$ = this.actions$.pipe(
    ofType(navigateToPhoneSetUpPage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Init,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToSavePhonePage$ = this.actions$.pipe(
    ofType(navigateToSavePhonePage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Save,
        ],
        {
          queryParamsHandling: 'merge',
        }
      );

      return [];
    })
  );

  @Effect()
  navigateToVerifyPhonePage$ = this.actions$.pipe(
    ofType(navigateToVerifyPhonePage),
    switchMap(() => {
      this.router.navigate(
        [
          this.authRouteNames.Entry,
          this.authRouteNames.Verification.Entry,
          this.authRouteNames.Verification.Phone,
          this.authRouteNames.Verification.Code,
        ],
        { queryParamsHandling: 'merge' }
      );

      return [];
    })
  );

  @Effect()
  navigateToLoginPage$ = this.actions$.pipe(
    ofType(navigateToLoginPage),
    switchMap(() => {
      this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      return [];
    })
  );

  @Effect()
  signInFinished$ = this.actions$.pipe(
    ofType(signInFinishedRedirectAction),
    switchMap(({ path, queryParams }) => {
      this.router.navigate(path ? path : ['/'], { queryParams });

      return [];
    })
  );

  @Effect()
  navigateToHealthTalk$ = this.actions$.pipe(
    ofType(navigateToHealthTalkAction),
    switchMap(() =>
      this.tokenService.getAuthToken().pipe(
        switchMap((response: TokenGetAuthResponse) => {
          const url = `${this.envService.healthTalkGui}?temp_token=${response.token}`;
          window.open(url, '_blank');
          return of();
        }),
        catchError(({ error }) => [
          navigateToHealthTalkFailureAction({ error }),
        ])
      )
    )
  );

  constructor(
    private redirectService: AuthRedirectService,
    private tokenService: TokenService,
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private storage: StorageService<StorageKeys>,
    private medrecordOAuthService: MedrecordOAuthService,
    private envService: EnvService,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames
  ) {}
}
